import { ethers, BigNumber } from "ethers";
import axios from "axios";
import * as seasonPassNFT from "../SeasonPassNFT.json";
require("dotenv").config();

const CONTRACT_ADDRESS = process.env.REACT_APP_SEASONPASS_CONTRACT_ADDRESS;
const SEASON_PASS_NFT_ABI = seasonPassNFT.abi;

const getFestivalSeasonContract = () => {
  // A Web3Provider wraps a standard Web3 provider, which is
  // what MetaMask injects as window.ethereum into each page
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  // The MetaMask plugin also allows signing transactions to
  // send ether and pay to change state within the blockchain.
  // For this, you need the account signer...
  const signer = provider.getSigner();

  const festivalDistrictNFTContract = new ethers.Contract(
    CONTRACT_ADDRESS,
    SEASON_PASS_NFT_ABI,
    signer
  );

  return festivalDistrictNFTContract;
};

const getFestivalSeasonContractReadOnly = () => {
  const PROVIDER_RPC_URL = process.env.REACT_APP_PROVIDER;
  const provider = new ethers.providers.JsonRpcProvider(PROVIDER_RPC_URL);

  const festivalDistrictNFTContract = new ethers.Contract(
    CONTRACT_ADDRESS,
    SEASON_PASS_NFT_ABI,
    provider
  );

  return festivalDistrictNFTContract;
};

const buyFestivalSeasonNFT = async (address, count) => {
  const contract = getFestivalSeasonContract();

  const PRICE_PER_SEASONPASS = await contract.PRICE_SEASONPASS();

  const actual_price = BigNumber.from(PRICE_PER_SEASONPASS).mul(count);

  try {
    return contract.mintSeasonPassNFT(address, count, { value: actual_price });
  } catch (err) {
    return err;
  }
};

const buyFestivalSeasonNFTByReferral = async (address, count, referralId) => {
  const contract = getFestivalSeasonContract();

  const PRICE_PER_SEASONPASS = await contract.PRICE_SEASONPASS();

  const actual_price = BigNumber.from(PRICE_PER_SEASONPASS).mul(count);

  try {
    return contract.mintSeasonPassNFTByReferral(
      address,
      count,
      Number(referralId),
      { value: actual_price }
    );
  } catch (err) {
    return err;
  }
};

const getReferees = async (referralId) => {
  const contract = getFestivalSeasonContractReadOnly();
  try {
    return contract.getReferees(referralId);
  } catch (err) {
    return err;
  }
};

const getMintPrice = async () => {
  const contract = getFestivalSeasonContractReadOnly();
  try {
    const ethPrice = ethers.utils.formatUnits(
      await contract.PRICE_SEASONPASS()
    );
    const { data: fiatPrice } = await axios.get(
      "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD,EUR&api_key=455406f3e93be85ceecfdc12e02aedf86ec0b26788ee0a514cbb6c42e33f8fd2"
    );
    return {
      ethPrice,
      usdPrice:
        fiatPrice?.Response === "Error"
          ? null
          : Math.round(ethPrice * fiatPrice.USD),
      euroPrice:
        fiatPrice?.Response === "Error"
          ? null
          : Math.round(ethPrice * fiatPrice.EUR),
    };
  } catch (err) {
    return null;
  }
};

const registerAsReferrer = async () => {
  const contract = getFestivalSeasonContract();
  try {
    return contract.registerAsReferrer();
  } catch (err) {
    return err;
  }
};

const getReferrers = async () => {
  const contract = getFestivalSeasonContractReadOnly();
  const filter = contract.filters.RegisterAsReferrer(null, null);
  const events = await contract.queryFilter(filter);
};

const getReferrerFromReferralId = async (referralId) => {
  const contract = getFestivalSeasonContractReadOnly();
  const filter = contract.filters.RegisterAsReferrer(null, referralId);
  const events = await contract.queryFilter(filter);
  // console.log(events)
  const referrerAddress = events[0].topics[1];
  return referrerAddress;
};

// TODO: Check if a wallet can have only one referralId
// Right now multiple referralIds from a single wallet possible
const getReferralIdFromReferrer = async (referrerAddress) => {
  const contract = getFestivalSeasonContractReadOnly();
  const filter = contract.filters.RegisterAsReferrer(referrerAddress, null);
  const events = await contract.queryFilter(filter);
  const referralIds = [];
  for (let referralIdTx of events) {
    const referralId = referralIdTx.topics[2];
    referralIds.push(Number(referralId.toString()));
  }
  return referralIds[0];
};

const getMintsByReferralId = async (referralId) => {
  const contract = getFestivalSeasonContractReadOnly();
  const filter = contract.filters.MintByReferral(referralId, null, null);
  const events = await contract.queryFilter(filter);
  const mints = [];
  for (let mintTx of events) {
    const mint = {
      blockNumber: mintTx.blockNumber,
      count: Number(mintTx.args.count),
      referee: mintTx.args.referee,
      referralId: Number(mintTx.args.referralId),
      txHash: mintTx.transactionHash,
      commissionAmount: ethers.utils.formatUnits(mintTx.args.commissionAmount),
    };
    mints.push(mint);
  }
  return mints;
};

const getTokenTransfers = async (from = null, to = null, tokenId = null) => {
  const contract = getFestivalSeasonContractReadOnly();
  const filter = contract.filters.Transfer(from, to, tokenId);
  const events = await contract.queryFilter(filter);
  const tokens = [];
  for (let transferTx of events) {
    const token = {
      blockNumber: transferTx.blockNumber,
      from: transferTx.args.from,
      to: transferTx.args.to,
      tokenId: Number(transferTx.args.tokenId),
      txHash: transferTx.transactionHash,
    };
    tokens.push(token);
  }
  return tokens;
};

const getOwnerOfToken = async (tokenId) => {
  const contract = getFestivalSeasonContractReadOnly();
  const owner = await contract.ownerOf(tokenId);
  console.log("Owner of tokenId", tokenId, "is", owner);
  return owner;
};

const latestUserTransfersForToken = (tokenTransfers) => {
  const tokenTransfersObjPerTokenId = {};
  for (let transferTx of tokenTransfers) {
    if (!tokenTransfersObjPerTokenId[transferTx.tokenId]) {
      tokenTransfersObjPerTokenId[transferTx.tokenId] = transferTx;
    } else if (
      tokenTransfersObjPerTokenId[transferTx.tokenId].blockNumber <
      transferTx.blockNumber
    ) {
      tokenTransfersObjPerTokenId[transferTx.tokenId] = transferTx;
    }
  }
  return tokenTransfersObjPerTokenId;
};

const getTokensOwnedByWalletAddress = async (walletAddress) => {
  const tokensReceived = latestUserTransfersForToken(
    await getTokenTransfers(null, walletAddress, null)
  );
  const tokensSent = latestUserTransfersForToken(
    await getTokenTransfers(walletAddress, null, null)
  );

  const tokensOwned = [];

  const tokenIds = Object.keys(tokensReceived);

  for (let tokenId of tokenIds) {
    if (!tokensSent[tokenId]) {
      tokensOwned.push(tokensReceived[tokenId]);
    } else {
      if (
        tokensReceived[tokenId].blockNumber > tokensSent[tokenId].blockNumber
      ) {
        tokensOwned.push(tokensReceived[tokenId]);
      }
    }
  }
  return tokensOwned;
};

export {
  CONTRACT_ADDRESS,
  buyFestivalSeasonNFT,
  buyFestivalSeasonNFTByReferral,
  getReferees,
  registerAsReferrer,
  getReferrers,
  getReferrerFromReferralId,
  getReferralIdFromReferrer,
  getMintsByReferralId,
  getMintPrice,
  getTokensOwnedByWalletAddress,
};

import React from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";
import { styled } from "@mui/system";

import { SectionTilesProps } from "../../utils/SectionProps";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const PoweredByBLock = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const { t } = useTranslation();
  const StyledWholePageGrid = styled(Grid)(({ theme }) => ({
    width: "100vw",
    height: "auto",
    margin: "auto",
    display: "grid",
    placeItem: "center",
    padding: "100px 0",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "50px 0",
    },
  }));

  const StyledWholePageInnerGrid = styled(Grid)(({ theme }) => ({
    width: "80%",
    margin: "auto",
  }));

  const StyledSpan = styled("div")(({ theme }) => ({
    color: "#ffb355",
    fontFamily: "Raleway, Sans-serif",
    fontSize: "50px",
    fontWeight: 700,
    lineHeight: "80px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "50px",
    },
  }));

  const StyledImage = styled("img")(({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      maxWidth: "300px",
    },
  }));

  return (
    <StyledWholePageGrid>
      <StyledWholePageInnerGrid
        container
        direction="row"
        justifyContent="center"
        spacing={4}
      >
        <Grid item md={12} xs={12} justifyContent="center" textAlign="center">
          <StyledSpan>{t("poweredBy")}</StyledSpan>
        </Grid>
        <Grid container spacing={3} marginTop="30px" marginBottom="60px">
          <Grid
            item
            md={4}
            xs={12}
            display="flex"
            direction="column"
            alignItems="center"
          >
            <StyledImage src="/images/dcl.png" alt="decentraland" />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            display="flex"
            direction="column"
            alignItems="center"
          >
            <StyledImage src="/images/vegas.png" alt="vegasCity" />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            display="flex"
            direction="column"
            alignItems="center"
          >
            <StyledImage src="/images/stadio.png" alt="stadio plus" />
          </Grid>
        </Grid>
      </StyledWholePageInnerGrid>
    </StyledWholePageGrid>
  );
};

PoweredByBLock.propTypes = propTypes;
PoweredByBLock.defaultProps = defaultProps;

export default PoweredByBLock;

import React from "react";
import Header from "../components/layout/Header";

const LayoutDefault = ({ children }) => (
  <>
    <Header navPosition="right" className="reveal-from-bottom" />
    <main
    // style={{
    //   backgroundColor: "transparent",
    //   backgroundImage: "linear-gradient(80deg, #ffb355 0%, #f2295b 100%)",
    //   transition: "background 0.3s, border-radius 0.3s, opacity 0.3s",
    // }}
    >
      <div className="elementor-background-video-container">
        <div
          style={{
            backgroundColor: "transparent",
            backgroundImage: "linear-gradient(80deg, #ffb355 0%, #f2295b 100%)",
            transition: "background 0.3s, border-radius 0.3s, opacity 0.3s",
            position: "fixed",
            width: "100vw",
            height: "100vh",
            opacity: "0.9",
            zIndex: "-90",
          }}
        ></div>
        <video
          autoPlay={true}
          loop
          muted={true}
          playsInline
          preload="auto"
          style={{
            height: "100vh",
            width: "100vw",
            position: "fixed",
            zIndex: "-99",
            backgroundColor: "transparent",
            backgroundImage: "linear-gradient(80deg, #ffb355 0%, #f2295b 100%)",
            transition: "background 0.3s, border-radius 0.3s, opacity 0.3s",
            objectFit: "cover",
          }}
        >
          <source
            src="https://laligaland.io/wp-content/uploads/2022/09/video-slider.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      {children}
    </main>
  </>
);

export default LayoutDefault;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";

import { SectionTilesProps } from "../../utils/SectionProps";
import MintErrorModal from "./partials/MintErrorModal";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const ContactBlock = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const { t } = useTranslation();
  const [contactResult, setContactResult] = useState("");
  const [contacting, setContacting] = useState(false);

  const StyledWholePageGrid = styled(Grid)(({ theme }) => ({
    width: "100vw",
    height: "auto",
    margin: "auto",
    display: "grid",
    placeItem: "center",
  }));

  const StyledWholePageInnerGrid = styled(Grid)(({ theme }) => ({
    width: "80%",
    margin: "auto",
  }));

  const StyledSpan = styled("div")(({ theme }) => ({
    color: "white",
    fontSize: "70px",
    fontWeight: 900,
    lineHeight: "70px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "45px",
      lineHeight: "50px",
      marginBottom: "20px",
    },
  }));

  const StyledInputWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  }));

  const StyledLabel = styled("label")(({ theme }) => ({
    fontSize: "25px",
    fontWeight: "900",
    color: "#ff5254",
    marginBottom: "5px",
    marginTop: "15px",
  }));

  const StyledInput = styled("input")(({ theme }) => ({
    fontFamily: "sans-serif",
    background: "white",
    border: "none",
    height: "40px",
    width: "100%",
    padding: "5px 20px",
  }));

  const StyledText = styled("textarea")(({ theme }) => ({
    fontFamily: "sans-serif",
    background: "white",
    border: "none",
    width: "100%",
    padding: "5px 20px",
  }));

  const StyledFormWrapper = styled(Grid)(({ theme }) => ({
    background: "#ffffffde",
    [theme.breakpoints.down("md")]: {
      padding: "15px !important",
    },
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: 1000,
    fontSize: "28px",
    padding: ".3em 1.5em",
    borderRadius: "50px",
    "&:disabled": {
      color: "#adadad",
    },
    "&:hover": {
      backgroundColor: "#ff5254",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
      padding: ".2em 2em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      padding: ".3em 2em",
    },
  }));

  const handleSubmit = (e) => {
    setContacting(true);
    e.preventDefault();
    const userForm = {
      to_name: process.env.REACT_APP_EMAIL_EMAIL_TO_NAME,
      from_email: e.target.email.value,
      from_name: e.target.name.value,
      message: e.target.message.value,
    };

    window.emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        userForm
      )
      .then((res) => {
        setContactResult(t("emailSentSuccess"));
        setContacting(false);
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => {
        setContactResult(t("emailSentFailed"));
        setContacting(false);
      });
  };
  return (
    <StyledWholePageGrid>
      <StyledWholePageInnerGrid
        container
        direction="row"
        justifyContent="center"
        spacing={4}
        alignItems="center"
      >
        <Grid item md={6} xs={12} justifyContent="center" alignItems="center">
          <StyledSpan>{t("contactTechTeam")}</StyledSpan>
        </Grid>
        <StyledFormWrapper
          item
          md={6}
          xs={12}
          justifyContent="center"
          textAlign="center"
          padding="35px"
        >
          <form onSubmit={(e) => handleSubmit(e)}>
            <StyledInputWrapper>
              <StyledLabel htmlFor="email">{t("email")} *</StyledLabel>
              <StyledInput
                required
                type="email"
                id="email"
                name="email"
                disabled={contacting}
              />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <StyledLabel htmlFor="name">{t("name")} *</StyledLabel>
              <StyledInput
                required
                type="text"
                id="name"
                name="name"
                disabled={contacting}
              />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <StyledLabel htmlFor="message">{t("message")} *</StyledLabel>
              <StyledText
                required
                rows="5"
                id="message"
                name="message"
                disabled={contacting}
              />
            </StyledInputWrapper>

            <StyledInputWrapper>
              <StyledLabel
                htmlFor="privacy"
                style={{ fontSize: "15px", margin: "15px 0" }}
              >
                <input
                  type="checkbox"
                  id="privacy"
                  name="privacy"
                  style={{ marginRight: "10px" }}
                  required
                  disabled={contacting}
                />
                {t("yesIAccept")}
              </StyledLabel>
            </StyledInputWrapper>
            <StyledButton type="submit" disabled={contacting}>
              {contacting ? `${t("sending")}...` : t("send")}
            </StyledButton>
          </form>
        </StyledFormWrapper>
      </StyledWholePageInnerGrid>
      {!!contactResult && (
        <MintErrorModal
          openModal={!!contactResult}
          setOpenModal={() => setContactResult("")}
          statusMessage={contactResult}
        />
      )}
    </StyledWholePageGrid>
  );
};

ContactBlock.propTypes = propTypes;
ContactBlock.defaultProps = defaultProps;

export default ContactBlock;

import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";

import SeasonLandPassImgMini from "../../assets/images/mintsite_graphic_1.png";
import { SectionTilesProps } from "../../utils/SectionProps";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const BenefitsBlock = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const { t } = useTranslation();
  const benefitsList = [
    t("benefit1"),
    t("benefit2"),
    t("benefit3"),
    t("benefit4"),
    t("benefit5"),
    t("benefit6"),
    t("benefit7"),
    t("benefit8"),
    t("benefit9"),
  ];
  const StyledWholePageGrid = styled(Grid)(({ theme }) => ({
    width: "100vw",
    height: "100vh",
    margin: "auto",
    display: "grid",
    placeItem: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "100px 0",
    },
  }));

  const StyledWholePageInnerGrid = styled(Grid)(({ theme }) => ({
    width: "80%",
    margin: "auto",
  }));

  const StyledBenefitText = styled("li")(({ theme }) => ({
    textAlign: "left",
    fontSize: "22px",
    fontWeight: "bold",
    lineHeight: "22px",
    color: "#fff",
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
      lineHeight: "16px",
    },
  }));
  return (
    <StyledWholePageGrid>
      <StyledWholePageInnerGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={4}
      >
        <Grid item md={6} xs={12}>
          <img src={SeasonLandPassImgMini} alt="Season land pass" />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          display="flex"
          direction="column"
          justifyContent="center"
        >
          <div className="container" style={{ textAlign: "center" }}>
            <h1 style={{ fontSize: "56px", fontWeight: 1000 }}>
              {t("benefitTitle")}:
            </h1>
          </div>
          <ul className="" style={{ textAlign: "center" }}>
            {benefitsList.map((benefit, index) => (
              <StyledBenefitText className="" key={index}>
                {benefit}
              </StyledBenefitText>
            ))}
          </ul>
        </Grid>
      </StyledWholePageInnerGrid>
    </StyledWholePageGrid>
  );
};

BenefitsBlock.propTypes = propTypes;
BenefitsBlock.defaultProps = defaultProps;

export default BenefitsBlock;

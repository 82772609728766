import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

import { Box } from "@mui/system";

import MintingBlock from "../components/sections/MintingBlock";
import BenefitsBlock from "../components/sections/BenefitsBlock";
import MembershipBlock from "../components/sections/MembershipBlock";
import PurchaseBlock from "../components/sections/PurchaseBlock";
import NewHeader from "../components/layout/NewHeader";
import NewFooter from "../components/layout/NewFooter";
import PoweredByBLock from "../components/sections/PoweredByBlock";
import ContactBlock from "../components/sections/ContactBlock";

const Home = () => {
  const app = useRef();

  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.utils.toArray(".panel").forEach((panel, i) => {
        ScrollTrigger.create({
          trigger: panel,
          start: "top top",
          snap: true,
          pinSpacing: false,
        });
      });
    }, app);

    return () => ctx.revert();
  });

  return (
    <Box ref={app}>
      <NewHeader />
      <div className="panel">
        <MintingBlock topDivider />
      </div>
      <div id="benefits" className="panel">
        <BenefitsBlock topDivider />
      </div>
      {/* <div className="panel">
        <MembershipBlock topDivider />
      </div> */}
      <div className="panel" id="purchaseBlock">
        <PurchaseBlock />
      </div>
      <div className="panel" style={{ background: "white" }}>
        <PoweredByBLock />
      </div>
      {/* <div className="panel" id="contactForm">
        <ContactBlock />
      </div> */}
      <NewFooter />
    </Box>
  );
};

export default Home;

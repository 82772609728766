import * as React from "react";
import { useTranslation } from "react-i18next";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";
import LaunchIcon from "@mui/icons-material/Launch";

import { CONTRACT_ADDRESS } from "../services/contract";
import { chainIdToNetworkParams } from "../services/networks";
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

export default function TokensTable({ tokens }) {
  const { t } = useTranslation();

  const StyledLink = styled("a")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    textDecorationLine: "underline !important",
    verticalAlign: "middle",
    "&:hover": {
      color: "#00549d !important",
    },
  }));

  return (
    <Table
      sx={{
        minWidth: 650,
        maxWidth: "900px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      aria-label="simple table"
    >
      <TableHead>
        <TableRow>
          <TableCell style={{ textAlign: "center", fontWeight: "bold" }}>
            #{t("TokenIds")}
          </TableCell>
          <TableCell style={{ textAlign: "center", fontWeight: "bold" }}>
            {t("OpenSea")}
          </TableCell>
          <TableCell style={{ textAlign: "center", fontWeight: "bold" }}>
            {t("Explorer")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tokens.map((row) => (
          <TableRow key={row.tokenId}>
            <TableCell
              component="th"
              scope="row"
              style={{ textAlign: "center" }}
            >
              {row.tokenId}
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <StyledLink
                href={`${chainIdToNetworkParams[CHAIN_ID].openseaUrl}/${CONTRACT_ADDRESS}/${row.tokenId}`}
                target="_blank"
              >
                OpenSea
                <LaunchIcon fontSize="xSmall" />
              </StyledLink>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <StyledLink
                href={`${chainIdToNetworkParams[CHAIN_ID].blockExplorerUrls[0]}/token/${CONTRACT_ADDRESS}?a=${row.tokenId}`}
                target="_blank"
              >
                Explorer
                <LaunchIcon fontSize="xSmall" />
              </StyledLink>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

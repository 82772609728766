import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Button, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import LaligaLand from "../../assets/images/laligalandLogo.svg";
import TwitterLogo from "../../assets/images/twitter.svg";
import DiscordLogo from "../../assets/images/discord.svg";
import InstagramLogo from "../../assets/images/instagram.svg";
import YoutubeLogo from "../../assets/images/youtube.svg";

const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  width: "80%",
  margin: "auto",
  display: "flex",
  justifyContent: "space-between",
  boxShadow: "0px",
  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const StyledMenuButton = styled(Button)({
  color: "#fff",
  textTransform: "capitalize",
  fontFamily: '"Raleway", Sans-serif',
  fontSize: "18px",
  fontWeight: "800",
  "&:hover": {
    "&:after": {
      background: "#ff5254",
      height: "10px",
      content: "''",
      width: "100%",
      display: "block",
      position: "absolute",
      bottom: "-11px",
    },
  },
});

const StyledLoginButton = styled(Button)({
  color: "#fff",
  fontWeight: 1000,
  textTransform: "capitalize",
  backgroundColor: "#ff5254",
  borderRadius: "20px",
  width: "200px",
  "&:hover": { backgroundColor: "#2f53a5", color: "white" },
});

const StyledImgLogo = styled("img")(() => ({
  marginRight: "5px",
  cursor: "pointer",
  "&:hover": {
    filter: "brightness(0.7)",
  },
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  fontFamily: "sans-serif",
  color: "white",
  fontWeight: "bold",
}));

const StyledDivider = styled(Divider)(() => ({
  border: 0,
  height: "1px",
  background: "#ffffff",
}));

const StyledLangIcon = styled("img")(() => ({
  padding: "0px",
  width: "20px",
  margin: "auto 15px",
  cursor: "pointer",
}));

const StyledListItemText = styled(ListItemText)(() => ({
  "& span": {
    color: "#fff",
    textTransform: "capitalize",
    fontFamily: '"Raleway", Sans-serif',
    fontSize: "18px",
    fontWeight: "800",
    "&:hover": {
      color: "#71192e",
    },
  },
}));

const Navbar = () => {
  const [state, setState] = useState({ left: false });
  const { t, i18n } = useTranslation();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: 250,
        height: "100vh",
        paddingTop: "40px",
        backgroundImage: "linear-gradient(80deg, #ffb355 0%, #f2295b 100%)",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding style={{ marginBottom: "0" }}>
          <StyledListItemButton
            onClick={() => {
              if (i18n.language === "en") {
                window.open(
                  "https://laligaland.io/community/?lang=en",
                  "_blank"
                );
              } else {
                window.open("https://laligaland.io/comunidad/", "_blank");
              }
            }}
          >
            <StyledListItemText primary={t("community")} />
          </StyledListItemButton>
        </ListItem>
        <StyledDivider />
        <ListItem disablePadding style={{ marginBottom: "0" }}>
          <StyledListItemButton
            onClick={() => {
              if (i18n.language === "en") {
                window.open("https://laligaland.io/explore/?lang=en", "_blank");
              } else {
                window.open("https://laligaland.io/explore/", "_blank");
              }
            }}
          >
            <StyledListItemText primary={t("explore")} />
          </StyledListItemButton>
        </ListItem>
        <StyledDivider />
        <ListItem disablePadding style={{ marginBottom: "0" }}>
          <StyledListItemButton
            onClick={() => {
              if (i18n.language === "en") {
                window.open("https://laligaland.io/news/?lang=en", "_blank");
              } else {
                window.open("https://laligaland.io/noticias/", "_blank");
              }
            }}
          >
            <StyledListItemText primary={t("news")} />
          </StyledListItemButton>
        </ListItem>
        <StyledDivider />
        <StyledDivider />
        <ListItem disablePadding style={{ marginBottom: "0" }}>
          <StyledListItemButton
            onClick={() =>
              window.open(
                "https://play.decentraland.org/?realm=baldr&position=-141%2C71",
                "_blank"
              )
            }
          >
            <StyledListItemText primary={t("IrAlaligaLand")} />
          </StyledListItemButton>
        </ListItem>
        <StyledDivider />
      </List>
    </Box>
  );

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.8);",
          boxShadow: "none",
          position: "fixed",
          height: "70px",
        }}
      >
        <StyledToolBar>
          <Link to="/" style={{ height: "60%" }}>
            <img
              src={LaligaLand}
              alt="Laliga land logo"
              style={{ height: "100%" }}
            />
          </Link>
          <Box
            direction="row"
            sx={{ display: { xs: "none", md: "flex" }, flexDirection: "row" }}
          >
            <StyledMenuButton
              variant="body1"
              onClick={() => {
                if (i18n.language === "en") {
                  window.open(
                    "https://laligaland.io/community/?lang=en",
                    "_blank"
                  );
                } else {
                  window.open("https://laligaland.io/comunidad/", "_blank");
                }
              }}
            >
              {t("community")}
            </StyledMenuButton>
            <StyledMenuButton
              variant="body1"
              onClick={() => {
                if (i18n.language === "en") {
                  window.open(
                    "https://laligaland.io/explore/?lang=en",
                    "_blank"
                  );
                } else {
                  window.open("https://laligaland.io/explore/", "_blank");
                }
              }}
            >
              {t("explore")}
            </StyledMenuButton>
            <StyledMenuButton
              variant="body1"
              onClick={() => {
                if (i18n.language === "en") {
                  window.open("https://laligaland.io/news/?lang=en", "_blank");
                } else {
                  window.open("https://laligaland.io/noticias/", "_blank");
                }
              }}
            >
              {t("news")}
            </StyledMenuButton>
            <StyledMenuButton
              variant="body1"
              onClick={() =>
                i18n.changeLanguage(i18n.language === "en" ? "es" : "en")
              }
            >
              {i18n.language === "en" ? (
                <StyledLangIcon src="/images/es.png" alt="ES" />
              ) : (
                <StyledLangIcon src="/images/en.png" alt="EN" />
              )}
            </StyledMenuButton>
          </Box>

          <Box
            sx={{ display: { xs: "none", lg: "flex" }, flexDirection: "row" }}
          >
            <StyledImgLogo
              src={DiscordLogo}
              sx={{ width: "42px" }}
              onClick={() =>
                window.open("https://discord.gg/dTEBQZ32rY", "_blank")
              }
            />
            {/* <StyledImgLogo
              src={InstagramLogo}
              sx={{ width: "35px" }}
              onClick={() =>
                window.open(
                  "https://www.instagram.com/laligaland.io/",
                  "_blank"
                )
              }
            /> */}
            <StyledImgLogo
              src={TwitterLogo}
              sx={{ width: "25px" }}
              onClick={() =>
                window.open("https://twitter.com/LaLigaLand", "_blank")
              }
            />
            <StyledImgLogo
              src={YoutubeLogo}
              sx={{ width: "31px" }}
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCJEhmGerwlLcEeoGT_dKjzA",
                  "_blank"
                )
              }
            />
          </Box>
          <Box>
            <StyledLoginButton
              sx={{ display: { xs: "none", md: "block" } }}
              onClick={() =>
                window.open(
                  "https://play.decentraland.org/?realm=baldr&position=-141%2C71",
                  "_blank"
                )
              }
            >
              {t("IrAlaligaLand")}
            </StyledLoginButton>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                  color: "white",
                  alignItems: "center",
                },
              }}
              onClick={() =>
                i18n.changeLanguage(i18n.language === "en" ? "es" : "en")
              }
            >
              {i18n.language === "es" ? (
                <StyledLangIcon src="/images/en.png" alt="EN" />
              ) : (
                <StyledLangIcon src="/images/es.png" alt="ES" />
              )}
            </Box>
            <IconButton
              onClick={toggleDrawer("left", true)}
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                  color: "white",
                  alignItems: "center",
                },
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </StyledToolBar>
      </AppBar>
      <Drawer open={state["left"]} onClose={toggleDrawer("left", false)}>
        {list("left")}
      </Drawer>
    </div>
  );
};

export default Navbar;

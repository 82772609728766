import React from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";
import { styled } from "@mui/system";

import { SectionTilesProps } from "../../utils/SectionProps";
import SeasonLandPassImgMini from "../../assets/images/mintsite_graphic_6.png";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const MembershipBlock = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const { t } = useTranslation();
  const StyledWholePageGrid = styled(Grid)(({ theme }) => ({
    width: "100vw",
    height: "100vh",
    margin: "auto",
    display: "grid",
    placeItem: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      margin: "100px 0",
    },
  }));

  const StyledMemberShipImg = styled("img")(({ theme }) => ({
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }));

  const StyledWholePageInnerGrid = styled(Grid)(({ theme }) => ({
    width: "80%",
    margin: "auto",
  }));

  const StyledMembershipGrid = styled(Grid)(({ theme }) => ({
    whiteSpace: "pre-line",
    display: "grid",
    textAlign: "center",
    placeItems: "center",
    marginTop: "10px",
    fontSize: "30px",
    fontWeight: "bold",
    color: "white",
    padding: "1em 0",
  }));

  const StyledTextSpan = styled("div")(({ theme }) => ({
    fontSize: "56px",
    fontWeight: 1000,
    lineHeight: "70px",
    color: "#fff",
    [theme.breakpoints.down("lg")]: {
      fontSize: "36px",
      fontWeight: 800,
      lineHeight: "50px",
    },
  }));

  return (
    <StyledWholePageGrid>
      <StyledWholePageInnerGrid
        container
        direction="row-reverse"
        justifyContent="space-between"
        spacing={4}
      >
        <Grid item md={6} xs={12}>
          <StyledMemberShipImg
            src={SeasonLandPassImgMini}
            alt="Season land pass"
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          display="flex"
          direction="column"
          justifyContent="center"
        >
          <div
            className="container"
            style={{ textAlign: "Left", lineHeight: "50px" }}
          >
            <StyledTextSpan>{t("memberShipTitle")}</StyledTextSpan>
          </div>
        </Grid>
      </StyledWholePageInnerGrid>
      <StyledWholePageInnerGrid
        container
        direction="row"
        justifyContent="space-around"
      >
        <StyledMembershipGrid item md={3} xs={12} className="bezel-effect">
          {t("memberShip1")}
        </StyledMembershipGrid>
        <StyledMembershipGrid item md={3} xs={12} className="bezel-effect">
          {t("memberShip2")}
        </StyledMembershipGrid>
        <StyledMembershipGrid item md={3} xs={12} className="bezel-effect">
          {t("memberShip3")}
        </StyledMembershipGrid>
      </StyledWholePageInnerGrid>
    </StyledWholePageGrid>
  );
};

MembershipBlock.propTypes = propTypes;
MembershipBlock.defaultProps = defaultProps;

export default MembershipBlock;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { useTranslation, Trans } from "react-i18next";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";

import { SectionTilesProps } from "../../utils/SectionProps";
import { connectWallet, getCurrentWalletConnected } from "../../utils/interact";
import SeasonLandPassImgMini from "../../assets/images/mintsite_graphic_3.png";
import {
  buyFestivalSeasonNFT,
  buyFestivalSeasonNFTByReferral,
  getMintPrice,
} from "../../services/contract";
import MintErrorModal from "./partials/MintErrorModal";

const { chainIdToNetworkParams } = require("../../services/networks");

const CROSSMINT_CLIENT_ID_WITH_REFERRAL =
  process.env.REACT_APP_CROSSMINT_CLIENT_ID_WITH_REFERRALID;
const CROSSMINT_CLIENT_ID_WITHOUT_REFERRAL =
  process.env.REACT_APP_CROSSMINT_CLIENT_ID_WITHOUT_REFERRALID;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const PurchaseBlock = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [walletAddress, setWallet] = useState("");
  const [rightNetwork, setRightNetwork] = useState(false);
  const [status, setStatus] = useState("");
  const [mintCount, setMintCount] = useState(1);
  const [crossMintCount, setCrossMintCount] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [openMintResultModal, setOpenMintResultModal] = useState(false);
  const [referralId, setReferralId] = useState("");
  const [isMinting, setIsMinting] = useState(false);
  const [mintPrice, setMintPrice] = useState({});

  const checkConnectedChainId = async () => {
    const currentChainId = await window.ethereum.request({
      method: "eth_chainId",
    });
    if (currentChainId == parseInt(CHAIN_ID)) {
      setRightNetwork(true);
    } else {
      setRightNetwork(false);
    }
  };

  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          await checkConnectedChainId();
          setStatus(t("clickMintButton"));
        } else {
          setWallet("");
          setStatus(`🦊 ${t("connectMetaMask")}`);
        }
      });

      window.ethereum.on("chainChanged", (chainId) => {
        chainId = parseInt(chainId, 16);
        if (chainId == parseInt(CHAIN_ID)) {
          setRightNetwork(true);
        } else {
          setRightNetwork(false);
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            {t("youMustInstallMetaMask")}
          </a>
        </p>
      );
    }
  };

  const initMintPrice = async () => {
    const res = await getMintPrice();
    if (res) setMintPrice(res);
  };

  useEffect(() => {
    const init = async () => {
      initMintPrice();
      addWalletListener();

      const { address, status } = await getCurrentWalletConnected();
      setWallet(address);
      setStatus(status);
      if (address) await checkConnectedChainId();
    };
    init();
  }, []);

  useEffect(() => {
    const referralIdQuery = new URLSearchParams(search).get("referrer");
    if (referralIdQuery) setReferralId(referralIdQuery);
  }, [search]);

  const StyledSpan = styled("span")(() => ({
    fontSize: "20px",
    color: "white",
    whiteSpace: "pre-line",
    "& a": {
      color: "white !important",
      textDecoration: "underline",
    },
  }));

  const ThanksForPurchase = () => {
    return (
      <StyledSpan>
        <Trans i18nKey="mintSucceed" components={[<a />]} />
      </StyledSpan>
    );
  };

  const buyNFTWithMetamask = async () => {
    try {
      let tx;
      if (referralId.length > 0) {
        tx = await buyFestivalSeasonNFTByReferral(
          walletAddress,
          mintCount,
          referralId
        );
      } else {
        tx = await buyFestivalSeasonNFT(walletAddress, mintCount);
      }
      setIsMinting(true);
      const txReceipt = await tx.wait();
      setIsMinting(false);
      // const tokenId = txReceipt.events[1].args[0];
      setOpenMintResultModal(<ThanksForPurchase />);
    } catch (e) {
      if (e.code === "INSUFFICIENT_FUNDS") {
        setOpenMintResultModal(t("insufficientFunds"));
      } else if (e._isProviderError) {
        setOpenMintResultModal(`${e.data.message}`);
      } else {
        setOpenMintResultModal(`${e.reason}`);
      }
      setIsMinting(false);
    }
  };

  const connectWalletPressed = async () => {
    //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    setOpenModal(true);
  };

  const StyledWholePageGrid = styled(Grid)(({ theme }) => ({
    margin: "auto",
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    placeItem: "center",
    [theme.breakpoints.down("lg")]: {
      height: "auto",
      margin: "70px 0",
    },
  }));

  const StyledWholePageInnerGrid = styled(Grid)(({ theme }) => ({
    width: "80%",
    margin: "0 auto",
    display: "flex",
  }));

  const StyledPurchasePageButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: 1000,
    fontSize: "28px",
    padding: ".3em 1.5em",
    borderRadius: "50px",
    "&:disabled": {
      color: "#adadad",
    },
    "&:hover": {
      backgroundColor: "#ff5254",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
      padding: ".2em 2em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      padding: ".3em 2em",
    },
  }));

  const StyledBuyButton = styled(CrossmintPayButton)(({ theme }) => ({
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: 1000,
    fontSize: "28px",
    padding: ".3em 1.5em",
    borderRadius: "50px",
    "&:disabled": {
      color: "#adadad",
    },
    "&:hover": {
      backgroundColor: "#ff5254",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "22",
      padding: ".2em 2em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      padding: ".3em 2em",
    },
  }));

  const StyledMintButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: 1000,
    fontSize: "28px",
    padding: ".3em 1.5em",
    borderRadius: "50px",
    "&:disabled": {
      color: "#adadad",
    },
    "&:hover": {
      backgroundColor: "#ff5254",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
      padding: ".2em 2em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      padding: ".3em 2em",
    },
  }));

  const StyledCheckoutText = styled("div")(({ theme }) => ({
    color: "#fff",
    fontWeight: 500,
    fontSize: "23px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  }));

  const StyledDivider = styled("div")(({ theme }) => ({
    width: "8px",
    height: "550px",
    display: "grid",
    placeItems: "center",
    alignSelf: "center",
    backgroundColor: "#cfcfcf",
    marginBottom: "-100px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  const StyledMintInputGrid = styled("div")(({ theme }) => ({
    display: "grid",
    placeItems: "center",
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    fontSize: "45px",
    padding: ".5em .9em 1.2em .9em",
    marginRight: "15px",
    height: "50px",
  }));

  const StyledInput = styled("input")(({ theme }) => ({
    fontFamily: "sans-serif",
    background: "none",
    width: "35px",
    border: "none",
    outline: "none",
    fontSize: "35px",
    padding: "0",
  }));

  const StyledPurchaseText = styled("div")(({ theme }) => ({
    color: "white",
    fontSize: "50px",
    fontWeight: 1000,
    lineHeight: "60px",
    whiteSpace: "pre-line",
    [theme.breakpoints.down("lg")]: {
      fontSize: "35px",
      lineHeight: "45px",
      fontWeight: 900,
    },
  }));

  const StyledDisclaimerText = styled("div")(({ theme }) => ({
    color: "white",
    marginTop: "40px",
    textAlign: "center",
    fontSize: "30px",
    fontWeight: 800,
    lineHeight: "30px",
    whiteSpace: "pre-line",
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
      lineHeight: "25px",
      fontWeight: 700,
    },
  }));
  return (
    <>
      <StyledWholePageGrid>
        <StyledWholePageInnerGrid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            md={5}
            xs={12}
            display="flex"
            direction="column"
            justifyContent="center"
          >
            <img
              src={SeasonLandPassImgMini}
              alt="Season land pass"
              align="center"
              style={{ width: "50%", margin: "30px auto" }}
            />
            <div className="container" style={{ textAlign: "center" }}>
              <StyledPurchaseText>
                {t("purchaseUsingCreditCard", {
                  price: `${mintPrice?.ethPrice}ETH`
                })}
              </StyledPurchaseText>
            </div>
            <div style={{ margin: "50px auto" }}>
              <StyledBuyButton
                clientId={
                  referralId.length > 0
                    ? CROSSMINT_CLIENT_ID_WITH_REFERRAL
                    : CROSSMINT_CLIENT_ID_WITHOUT_REFERRAL
                }
                mintConfig={{
                  type: "erc-721",
                  totalPrice: `${mintPrice?.ethPrice * crossMintCount}`,
                  _count: `${crossMintCount}`,
                  _referralId: `${referralId}`,
                }}
                environment={CHAIN_ID == "1" ? "production" : "staging"}
                className="my-custom-crossmint-button"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StyledMintInputGrid className="bezel-effect">
                <StyledInput
                  type="number"
                  onChange={(e) => setCrossMintCount(e.target.value)}
                  value={crossMintCount}
                />
              </StyledMintInputGrid>
              <div>
                <StyledCheckoutText>
                  {t("creditCardCheckoutFotNFTs")}
                </StyledCheckoutText>
                <StyledCheckoutText>{t("noWalletRequired")}</StyledCheckoutText>
              </div>
            </div>
          </Grid>
          <Grid item md={2} xs={0} container justifyContent="center">
            <StyledDivider />
          </Grid>
          <Grid
            item
            md={5}
            xs={12}
            display="flex"
            direction="column"
            justifyContent="center"
          >
            <img
              src={SeasonLandPassImgMini}
              alt="Season land pass"
              align="center"
              style={{ width: "50%", margin: "30px auto" }}
            />
            <div className="container" style={{ textAlign: "center" }}>
              <StyledPurchaseText>
                {t("purchaseUsingWeb3", {
                  ethPrice: `${mintPrice?.ethPrice}ETH`
                })}
              </StyledPurchaseText>
            </div>
            <div
              style={{
                margin: "50px auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <StyledPurchasePageButton onClick={connectWalletPressed}>
                {walletAddress
                  ? rightNetwork
                    ? t("connected")
                    : t("connectToNetwork", {
                        chainName:
                          chainIdToNetworkParams[CHAIN_ID]["chainName"],
                      })
                  : t("connectWallet")}
              </StyledPurchasePageButton>
            </div>
            {walletAddress && rightNetwork && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StyledMintInputGrid className="bezel-effect">
                  <StyledInput
                    type="number"
                    onChange={(e) => setMintCount(e.target.value)}
                    value={mintCount}
                  />
                </StyledMintInputGrid>
                <StyledMintButton
                  onClick={buyNFTWithMetamask}
                  disabled={!walletAddress || isMinting}
                >
                  {isMinting ? (
                    <span>
                      {t("minting")} <CircularProgress size="28px" />
                    </span>
                  ) : (
                    t("mint")
                  )}
                </StyledMintButton>
              </div>
            )}
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            display="flex"
            direction="column"
            justifyContent="center"
          >
            <StyledDisclaimerText>
              {t("purchaseBlockDisclaimer")}
            </StyledDisclaimerText>
          </Grid>
        </StyledWholePageInnerGrid>
      </StyledWholePageGrid>
      <MintErrorModal
        statusMessage={status}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <MintErrorModal
        statusMessage={openMintResultModal}
        openModal={!!openMintResultModal}
        setOpenModal={() => setOpenMintResultModal("")}
      />
    </>
  );
};

PurchaseBlock.propTypes = propTypes;
PurchaseBlock.defaultProps = defaultProps;

export default PurchaseBlock;

import * as React from "react";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LaunchIcon from "@mui/icons-material/Launch";

export default function MintTable({ referralId, mints }) {
  const { t } = useTranslation();
  const StyledLink = styled("a")(({ theme }) => ({
    textDecorationLine: "underline !important",
    verticalAlign: "middle",
    "&:hover": {
      color: "#00549d !important",
    },
  }));

  return (
    <Table
      sx={{
        minWidth: 650,
        maxWidth: "900px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      aria-label="simple table"
    >
      <TableHead>
        <TableRow>
          <TableCell style={{ textAlign: "center", fontWeight: "bold" }}>
            {t("walletUsingReferrerId", { referralId })}
          </TableCell>
          <TableCell style={{ textAlign: "center", fontWeight: "bold" }}>
            {t("mintCount")}
          </TableCell>
          <TableCell style={{ textAlign: "center", fontWeight: "bold" }}>
            {t("commissions")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {mints.map((row) => (
          <TableRow key={row.referee}>
            <TableCell
              component="th"
              scope="row"
              style={{ textAlign: "center" }}
            >
              <StyledLink
                href={`https://goerli.etherscan.io/tx/${row.txHash}`}
                target="_blank"
              >
                {`${row.referee.slice(0, 10)}...${row.referee.slice(-10)}`}{" "}
                <span>
                  <LaunchIcon fontSize="xSmall" />
                </span>
              </StyledLink>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              {row.count.toString()}
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              {row.commissionAmount.toString()}
            </TableCell>
          </TableRow>
        ))}
        {mints && mints.length >= 1 && (
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              style={{ textAlign: "right" }}
            >
              <strong>{t("total")}</strong>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <strong>{mints.reduce((a, b) => a + b.count * 1, 0)}</strong>
            </TableCell>
            <TableCell style={{ textAlign: "center" }}>
              <strong>
                {mints.reduce((a, b) => a + b.commissionAmount * 1, 0)}
              </strong>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

import React from "react";
import gsap from "gsap";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";

import { SectionTilesProps } from "../../utils/SectionProps";
import SeasonLandPassImg from "../../assets/images/mintsite_graphic_7.png";
import SeasonLandPassImgMini from "../../assets/images/mintsite_graphic_1.png";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const MintingBlock = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const { t } = useTranslation();

  const goToPurchase = () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: "#purchaseBlock",
    });
  };
  const goToBenefits = () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: "#benefits",
    });
  };
  const StyledWholePageGrid = styled(Grid)(({ theme }) => ({
    width: "100vw",
    height: "100vh",
    margin: "auto",
    display: "grid",
    placeItem: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      margin: "70px 0",
    },
  }));

  const StyledWholePageInnerGrid = styled(Grid)(({ theme }) => ({
    width: "80%",
    margin: "auto",
  }));

  const StyledMintPageButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: 1000,
    fontSize: "25px",
    padding: ".3em 1.5em",
    borderRadius: "50px",
    "&:disabled": {
      color: "#adadad",
    },
    "&:hover": {
      backgroundColor: "#ff5254",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
      padding: ".2em 1.3em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      padding: ".3em 0.7em",
    },
  }));
  return (
    <StyledWholePageGrid>
      <StyledWholePageInnerGrid
        container
        direction="row"
        justifyContent="space-between"
        spacing={4}
      >
        <Grid item md={6} xs={12}>
          <img src={SeasonLandPassImg} alt="Season land pass" />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          display="flex"
          direction="column"
          justifyContent="center"
        >
          <img
            src={SeasonLandPassImgMini}
            alt="Season land pass"
            align="center"
            style={{ width: "45%", margin: "auto" }}
          />
          <div className="container" style={{ textAlign: "center" }}>
            <h1
              style={{
                fontSize: "56px",
                fontWeight: 1000,
                whiteSpace: "pre-line",
              }}
            >
              {t("mintingBlockTitle")}
            </h1>
          </div>
          <div style={{ margin: "auto", display: "flex", justifyContent: "center", gap: "10px" }}>
            <StyledMintPageButton
              id="walletButton"
              style={{ width: "auto" }}
              onClick={goToPurchase}
            >
              {t("buyNow")}
            </StyledMintPageButton>
            <StyledMintPageButton
              id="walletButton"
              style={{ width: "auto" }}
              onClick={goToBenefits}
            >
              {t("benefits")}
            </StyledMintPageButton>
          </div>
        </Grid>
      </StyledWholePageInnerGrid>
    </StyledWholePageGrid>
  );
};

MintingBlock.propTypes = propTypes;
MintingBlock.defaultProps = defaultProps;

export default MintingBlock;
